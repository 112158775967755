import { FC } from "react"
import { css } from "@emotion/react"
import {
  breakpoints,
  colours,
  column,
  typographyStyles,
  vertical,
} from "design-kit"

import { BuyerType } from "../../shared-components/Calculators/types"

import { Section } from "./components/Section"
import { NewmoFaqs as NewMortgageFAQs, RemoFaqs as RemortgageFAQs } from "./FAQ"

const TITLE = "Frequently asked questions"

const FAQsSection: FC<{
  mortgageType?: BuyerType
}> = ({ mortgageType }) => (
  <Section
    css={css`
      background-color: ${colours.white};
    `}
    label={TITLE}
  >
    <div
      css={css`
        ${column({
          widthMobile: 4,
          widthTablet: 6,
          widthDesktop: 10,
          offsetDesktop: 1,
        })}

        margin-top: ${vertical.xl};
        margin-bottom: ${vertical.l};

        ${breakpoints.desktop`
          margin-top: ${vertical.xxl};
        `}
      `}
    >
      <h2
        css={css`
          ${typographyStyles.statement2};

          margin: 0;

          ${breakpoints.desktop`
            text-align: center;
          `}
        `}
      >
        {TITLE}
      </h2>
    </div>

    <div
      css={css`
        ${column({
          widthMobile: 4,
          widthTablet: 6,
          widthDesktop: 8,
          offsetDesktop: 2,
        })}

        margin-bottom: calc(${vertical.xl} - ${vertical.l});

        ${breakpoints.desktop`
          margin-bottom: calc(${vertical.xxl} - ${vertical.xl});
        `}
      `}
    >
      {/* Default to the NewMortgage FAQs */}
      {mortgageType === "Remortgage" ? <RemortgageFAQs /> : <NewMortgageFAQs />}
    </div>
  </Section>
)

export default FAQsSection
