import { FunctionComponent } from "react"
import styled from "@emotion/styled"
import {
  Body,
  breakpoints,
  colours,
  column,
  GridRow,
  gridSettings,
  horizontal,
  mkColumn,
  mkSubGridRow,
  typographyStyles,
  vertical,
} from "design-kit"

const SubGridRow = mkSubGridRow(breakpoints)(gridSettings)
const subColumn = mkColumn(breakpoints)(gridSettings)

const Section = styled.section`
  padding: ${vertical.l} 0;
  background-color: ${colours.greyScale.grey25};
`

const Container = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
  color: ${colours.offBlack};
  background-color: ${colours.white};
  border: 1px solid ${colours.greyScale.grey50};
  border-radius: 4px;
  padding: ${vertical.xl} ${horizontal.s};

  ${breakpoints.tablet`
    padding: ${vertical.xl} 0;
  `}
`

const InnerContainer = styled.div`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 4,
    widthDesktop: 7,
    offsetDesktop: 1,
  })}

  ${breakpoints.tablet`
    padding-left: ${horizontal.m};
    padding-right: ${horizontal.m};
  `}

  ${breakpoints.desktop`
    padding-left: 0;
    padding-right: 0;
  `}
`
const FormHeading = styled.h1`
  ${typographyStyles.headline2};
  margin: 0 0 ${vertical.xs};
`

const FormSubheading = styled(Body)`
  margin: 0 0 ${vertical.l};
`

/**
 * Use this scaffold as a wrapper around a form
 * to give it some clean styling.
 */
export const FormLayout: FunctionComponent<{
  heading: string
  subheading: string
}> = ({ heading, subheading, children }) => (
  <Section>
    <GridRow>
      <Container>
        <SubGridRow>
          <InnerContainer>
            <FormHeading>{heading}</FormHeading>
            <FormSubheading>{subheading}</FormSubheading>
            {children} {/* children should be the form itself */}
          </InnerContainer>
        </SubGridRow>
      </Container>
    </GridRow>
  </Section>
)

export const QuestionWrapper = styled.div`
  margin-bottom: ${vertical.l};
`

export const CTARow = styled.div`
  display: flex;
  flex-direction: row-reverse;

  > button {
    flex: 1;
  }

  > button:first-of-type {
    flex: 2;
  }

  > button + button {
    // margin-right because row-reverse (above)
    margin-right: 32px;
  }
`
