export const validateIsPositiveNumber = (s: string | null): string | null => {
  const err = "Please enter a number greater than 0"
  if (!s) {
    return err
  }

  const parsed = parseFloat(s)
  if (isNaN(parsed) || parsed <= 0) {
    return err
  }

  return null
}

export const validateMortgageTerm = (s: string | null): string | null => {
  const err = "Please enter a number greater than 0 and no more than 40"
  if (!s) {
    return err
  }

  const parsed = parseInt(s, 10)
  if (isNaN(parsed) || parsed <= 0 || parsed > 40) {
    return err
  }

  return null
}

export const validateRemainingBalance = (
  remainingBalance: string | null,
  propertyValue: string | null
): string | null => {
  if (remainingBalance === null || propertyValue === null) {
    return null
  }

  if (parseFloat(remainingBalance) > parseFloat(propertyValue)) {
    return "Your remaining balance can’t be more than your property value"
  }

  return null
}

export const validateCurrentMonthlyPayments = (
  currentMonthlyPayments: string | null,
  propertyValue: string | null
): string | null => {
  if (currentMonthlyPayments === null || propertyValue === null) {
    return null
  }

  if (parseFloat(currentMonthlyPayments) > parseFloat(propertyValue)) {
    return "Your current monthly payments can’t be more than your property value"
  }

  return null
}
