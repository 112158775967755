import { VFC, useRef } from "react"
import styled from "@emotion/styled"

import * as RD from "@heyhabito/remote-data"

import {
  breakpoints,
  IntegerInputQuestion,
  media,
  mixins,
  PrimaryTwoCTA,
  RadioButtonGroup,
  SelectInput,
  useMediaQuery,
  vertical,
} from "design-kit"

import {
  BuyerType,
  RepaymentMethod,
  Ordering,
  RateType,
} from "../../../shared-components/Calculators/types"
import { initialPeriodSelectOptions } from "../../../shared-components/Calculators/formatting"
import { EduContent } from "../../../shared-components/Calculators/EduContent"

import {
  ErrorsNames,
  NewMortgageBorrowingAmounts,
  NewMortgageFormInputNames,
} from "../types"
import { useScrollAndFocusUpdates } from "../utils/focus"
import { Section } from "../components/Section"
import { FormInner } from "../components/Form"
import { InputCard } from "../components/InputCard"
import { ContentArea } from "../components/ContentArea"
import { DealsCard } from "../components/DealsCard"
import { useNewMortgageForm } from "../hooks/useNewMortgageForm"

interface Props {
  borrowingAmounts: NewMortgageBorrowingAmounts
  shouldScrollToSection: boolean
  setIsOtherCostsExpanded: (ioce: boolean) => Promise<void>
}

const RefDiv = styled.div`
  width: 100%;
  ${mixins.invisible}
`

const SortByBox = styled.div`
  width: 100%;
  margin-bottom: ${vertical.m};

  ${breakpoints.desktop`
    width: 350px;
    margin-inline-start: auto;
  `}
`

const DealsSection: VFC<Props> = ({
  borrowingAmounts,
  shouldScrollToSection,
  setIsOtherCostsExpanded,
}) => {
  const sectionRef = useRef<HTMLDivElement>(null)

  useScrollAndFocusUpdates({ shouldScrollToSection, sectionRef })

  const allowsMotion = useMediaQuery(media.allowsMotion.query)

  const scrollToResults = (): void => {
    if (sectionRef.current) {
      window.scrollBy({
        behavior: allowsMotion ? "smooth" : "auto",
        top: sectionRef.current.getBoundingClientRect().top,
      })

      setTimeout(() => {
        sectionRef.current?.focus()
      }, 500)
    }
  }

  const { formState, onInputChange, formErrors, dealsRequest, onSubmit } =
    useNewMortgageForm(borrowingAmounts, scrollToResults)

  const hasResults =
    dealsRequest._tag === "Success" && dealsRequest.result.length > 0

  const selectedOrdering =
    dealsRequest._tag === "Success" && dealsRequest.result.length > 0
      ? dealsRequest.result[0].sortBy
      : Ordering.OverallCostAsc

  const hasError = !!(
    formErrors[ErrorsNames.MortgageTermRange] ||
    formErrors[ErrorsNames.MortgageTermShorterThanDealPeriod] ||
    formErrors[ErrorsNames.DealPeriodLongerThanMortgageTerm]
  )

  return (
    <Section label="Live deals" tabIndex={-1}>
      <InputCard
        headingId="habito-mortgage-calculator-newmo-input-deals-heading"
        title="Filter live deals"
      >
        <FormInner>
          <RadioButtonGroup
            title="Loan type"
            name="repayment-type"
            buttons={[
              {
                label: "Repayment",
                value: RepaymentMethod.CapitalAndInterest,
              },
              {
                label: "Interest only",
                value: RepaymentMethod.InterestOnly,
              },
            ]}
            layout={{
              mobile: "column",
              tablet: "row",
              desktop: "row",
            }}
            selectedValue={formState[NewMortgageFormInputNames.RepaymentType]}
            onChange={val =>
              onInputChange(val, NewMortgageFormInputNames.RepaymentType)
            }
          />

          <RadioButtonGroup
            title="Rate type"
            name="rate-type"
            buttons={[
              { label: "Fixed", value: RateType.Fixed },
              { label: "Variable", value: RateType.Variable },
            ]}
            layout="row"
            selectedValue={formState[NewMortgageFormInputNames.RateType]}
            onChange={val =>
              onInputChange(val, NewMortgageFormInputNames.RateType)
            }
          />

          <SelectInput
            title="Initial period (years)"
            options={initialPeriodSelectOptions}
            selected={formState[NewMortgageFormInputNames.InitialPeriod]}
            onChange={val => {
              onInputChange(val, NewMortgageFormInputNames.InitialPeriod)
            }}
            error={formErrors.DealPeriodLongerThanMortgageTerm}
          />

          <IntegerInputQuestion
            title="Mortgage term"
            hint={{ kind: "right", text: "years" }}
            value={formState[NewMortgageFormInputNames.MortgageTerm]}
            onInput={val => {
              onInputChange(val, NewMortgageFormInputNames.MortgageTerm)
            }}
            error={
              formErrors.MortgageTermRange ||
              formErrors.MortgageTermShorterThanDealPeriod
            }
          />
          <PrimaryTwoCTA
            text="Filter"
            disabled={hasError}
            isLoading={RD.isLoading(dealsRequest)}
            onClick={onSubmit}
            width="full-width"
          />
        </FormInner>
      </InputCard>

      <ContentArea>
        <RefDiv ref={sectionRef} />
        <SortByBox>
          <SelectInput
            title="Sort by"
            options={{
              [Ordering.OverallCostAsc]: "Overall cost",
              [Ordering.MonthlyRepaymentAsc]: "Monthly repayment",
              [Ordering.LendersFeeAsc]: "Lender fees",
              [Ordering.InterestRateAsc]: "Interest rate",
            }}
            selected={formState[NewMortgageFormInputNames.SortBy]}
            onChange={val => {
              onInputChange(val, NewMortgageFormInputNames.SortBy)
            }}
            disabled={RD.isLoading(dealsRequest) || hasError}
          />
        </SortByBox>
        <EduContent ordering={selectedOrdering} hasResults={hasResults} />
        <DealsCard
          dealsRequest={dealsRequest}
          sortBy={selectedOrdering}
          mortgageType={BuyerType.NewMortgage}
          onClickSeeOtherCosts={async () => {
            setIsOtherCostsExpanded(true)
          }}
          shouldShowOtherCostsButton={shouldScrollToSection}
        />
      </ContentArea>
    </Section>
  )
}

export default DealsSection
