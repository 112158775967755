import { useEffect, useState } from "react"

import {
  Errors,
  NewMortgageForm,
  FormErrors,
  ErrorsNames,
  NewMortgageFormInputNames,
} from "../types"

export const useNewMortgageFormValidation = (
  formState: NewMortgageForm,
  activeInput?: NewMortgageFormInputNames
): FormErrors => {
  const [formErrors, setFormErrors] = useState<FormErrors>({})

  const useHandleError = (
    errorCondition: boolean,
    errorName: ErrorsNames,
    inputName: NewMortgageFormInputNames
  ): void => {
    const errNotIncludedYet = (): boolean => {
      switch (errorName) {
        case ErrorsNames.DealPeriodLongerThanMortgageTerm:
          return !formErrors[ErrorsNames.MortgageTermShorterThanDealPeriod]
        case ErrorsNames.MortgageTermShorterThanDealPeriod:
          return !formErrors[ErrorsNames.DealPeriodLongerThanMortgageTerm]
        default:
          return true
      }
    }

    const shouldSetError =
      activeInput === inputName &&
      !formErrors[errorName] &&
      errorCondition &&
      errNotIncludedYet()

    const shouldUnsetError = formErrors[errorName] && !errorCondition

    useEffect(() => {
      if (shouldSetError) {
        setFormErrors({
          ...formErrors,
          [errorName]: Errors[errorName],
        })
      }

      if (shouldUnsetError) {
        setFormErrors({
          ...formErrors,
          [errorName]: undefined,
        })
      }
    }, [
      errorCondition,
      formErrors,
      errorName,
      shouldSetError,
      shouldUnsetError,
    ])
  }

  const { mortgageTerm, initialPeriod } = formState

  const parsedMortgageTerm = parseInt(mortgageTerm, 10)
  const parsedInitialPeriod = parseInt(initialPeriod, 10)

  const mortgageTermOutsideRange =
    isNaN(parsedMortgageTerm) ||
    parsedMortgageTerm <= 0 ||
    parsedMortgageTerm > 40

  const mortgageTermShorterThanDeal =
    !isNaN(parsedInitialPeriod) && parsedMortgageTerm < parsedInitialPeriod

  useHandleError(
    mortgageTermOutsideRange,
    ErrorsNames.MortgageTermRange,
    NewMortgageFormInputNames.MortgageTerm
  )
  useHandleError(
    mortgageTermShorterThanDeal,
    ErrorsNames.MortgageTermShorterThanDealPeriod,
    NewMortgageFormInputNames.MortgageTerm
  )
  useHandleError(
    mortgageTermShorterThanDeal,
    ErrorsNames.DealPeriodLongerThanMortgageTerm,
    NewMortgageFormInputNames.InitialPeriod
  )

  return formErrors
}
