import React from "react"
import { css } from "@emotion/react"
import { GridRow, breakpoints, vertical } from "design-kit"

type Props = React.PropsWithChildren<{
  label: string
  tabIndex?: -1
  className?: string
}>

export const Section = React.forwardRef<HTMLDivElement, Props>(
  ({ children, label, tabIndex, className }, ref) => (
    <section
      aria-label={label}
      className={className}
      css={css`
        ${breakpoints.desktop`
          padding-bottom: ${vertical.xl};
        `}
      `}
      ref={ref}
      tabIndex={tabIndex}
    >
      <GridRow>{children}</GridRow>
    </section>
  )
)
