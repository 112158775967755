import { media, useMediaQuery } from "design-kit"
import { RefObject, useEffect, useState } from "react"
import { STEP_TRANSITION_DURATION_MS } from "../components/CollapsibleSection"

export const useScrollAndFocusUpdates = ({
  shouldScrollToSection,
  sectionRef,
}: {
  shouldScrollToSection: boolean
  sectionRef: RefObject<HTMLDivElement>
}): void => {
  const allowsMotion = useMediaQuery(media.allowsMotion.query)

  const [areCardsCollapsing, setAreCardsCollapsing] = useState(allowsMotion)

  useEffect(() => {
    if (areCardsCollapsing) {
      const animationTimeout = setTimeout(() => {
        setAreCardsCollapsing(false)
      }, STEP_TRANSITION_DURATION_MS)

      return () => {
        clearTimeout(animationTimeout)
      }
    }
  }, [areCardsCollapsing])

  useEffect(() => {
    if (!areCardsCollapsing && shouldScrollToSection && sectionRef.current) {
      window.scrollBy({
        behavior: allowsMotion ? "smooth" : "auto",
        top: sectionRef.current.getBoundingClientRect().top,
      })

      const focusTimeout = setTimeout(() => {
        sectionRef.current?.focus()
      }, 500)

      return () => {
        clearTimeout(focusTimeout)
      }
    }
  }, [areCardsCollapsing, shouldScrollToSection])
}
