import { css } from "@emotion/react"
import {
  Body,
  breakpoints,
  colours,
  column,
  horizontal,
  typographyStyles,
  useMediaQuery,
  vertical,
} from "design-kit"
import React from "react"

import { Card } from "../../../shared-components/Calculators/Card"

interface InnerProps {
  description?: string
  headingId: string
  title: string
}

const InputCardInner: React.FC<InnerProps> = ({
  children,
  description,
  headingId,
  title,
}) => (
  <Card
    aria-labelledby={headingId}
    css={css`
      ${column({
        widthMobile: 4,
        widthTablet: 6,
        widthDesktop: 4,
      })}

      align-self: flex-start;

      ${breakpoints.desktop`
        position: sticky;
        top: ${vertical.m};
      `}
    `}
    element="article"
  >
    <h2
      css={css`
        ${typographyStyles.headline2}

        margin-bottom: ${vertical.xs};
      `}
      id={headingId}
    >
      {title}
    </h2>

    {description && (
      <Body
        css={css`
          margin-bottom: ${vertical.xs};
        `}
      >
        {description}
      </Body>
    )}

    {children}
  </Card>
)

interface Props extends InnerProps {
  // Used by Emotion
  className?: string
}

export const InputCard: React.FC<Props> = ({
  className: nonDesktopClassName,
  ...innerProps
}) => {
  const isDesktop = useMediaQuery(breakpoints.desktop.query)

  if (isDesktop) {
    return <InputCardInner {...innerProps} />
  }

  return (
    <div
      className={nonDesktopClassName}
      css={css`
        background-color: ${colours.offBlack};
        margin-left: -${horizontal.s};
        padding-left: ${horizontal.s};
        padding-top: ${vertical.l};
        width: 100%;

        ${breakpoints.tablet`
          margin-left: -${horizontal.l};
          margin-right: -${horizontal.s};
          padding-left: ${horizontal.l};
          padding-right: ${horizontal.s};
        `}
      `}
    >
      <InputCardInner {...innerProps} />
    </div>
  )
}
