import { VFC, useState, useRef, useEffect } from "react"
import { css } from "@emotion/react"

import * as RD from "@heyhabito/remote-data"
import { useSentry } from "@heyhabito/sentry"
import { FormSpec, FormProvider } from "@heyhabito/form-utils"

import {
  Body,
  colours,
  IntegerInputQuestion,
  media,
  PrimaryTwoCTA,
  RadioButtonGroup,
  SelectInput,
  useMediaQuery,
} from "design-kit"

import {
  FixedOrVariable,
  InitialPeriod,
  Years,
  mkYears,
  poundsToPence,
  BuyerType,
  RepaymentMethod,
  Ordering,
  RateType,
  MortgageType,
  MortgageDeal,
} from "../../../shared-components/Calculators/types"
import { getMortgageDeals } from "../../../shared-components/Calculators/api"
import { initialPeriodSelectOptions } from "../../../shared-components/Calculators/formatting"
import { EduContent } from "../../../shared-components/Calculators/EduContent"

import { useAmplitude } from "../../Shared/utils/amplitude"

import { RemortgageMonthlyPayments } from "../types"
import { initialPeriodToNumber, logUndefinedFields } from "../utils/forms"
import { validateMortgageTerm } from "../utils/validation"
import { Section } from "../components/Section"
import { FormInner } from "../components/Form"
import { InputCard } from "../components/InputCard"
import { ContentArea } from "../components/ContentArea"
import { DealsCard } from "../components/DealsCard"
import { useScrollAndFocusUpdates } from "../utils/focus"

interface DealsAnswers {
  rateType: FixedOrVariable
  initialPeriod: InitialPeriod
  mortgageTerm: string
  sortBy: Ordering
}

interface ParsedDealsAnswers {
  rateType: FixedOrVariable
  initialPeriod: InitialPeriod
  mortgageTerm: Years
  sortBy: Ordering
}

const DEFAULT_RATE_TYPE: FixedOrVariable = "Fixed"
const DEFAULT_SORT_BY: Ordering = Ordering.LendersFeeAsc

const formSpec: FormSpec<DealsAnswers> = {
  rateType: {
    defaultValue: DEFAULT_RATE_TYPE,
    toError: () => {
      return null
    },
  },
  initialPeriod: {
    toError: () => {
      return null
    },
  },
  mortgageTerm: { toError: validateMortgageTerm },
  sortBy: {
    defaultValue: DEFAULT_SORT_BY,
    toError: () => {
      return null
    },
  },
}

type NarrowedOrdering = Exclude<Ordering.InterestRateAsc, Ordering>

interface Props {
  shouldScrollToSection: boolean
  monthlyPayments: RemortgageMonthlyPayments
}

const DealsSection: VFC<Props> = ({
  shouldScrollToSection,
  monthlyPayments,
}) => {
  const sectionRef = useRef<HTMLDivElement>(null)

  useScrollAndFocusUpdates({ shouldScrollToSection, sectionRef })

  const allowsMotion = useMediaQuery(media.allowsMotion.query)

  const [showErrors, setShowErrors] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)

  const [dealsRequest, setDealsRequest] = useState<
    RD.RemoteData<Error, MortgageDeal[]>
  >(RD.NotAsked)

  const { logToSentry } = useSentry()
  const { logSubmittedForm } = useAmplitude(
    BuyerType.Remortgage,
    "deals_section"
  )

  const [cachedInitialPeriod, setCachedInitialPeriod] = useState<InitialPeriod>(
    monthlyPayments.initialPeriod
  )
  const [cachedMortgageTerm, setCachedMortgageTerm] = useState<Years>(
    monthlyPayments.mortgageTerm
  )
  const [cachedSortBy, setCachedSortBy] = useState<Ordering>(DEFAULT_SORT_BY)

  const fetchDeals = async (answers: ParsedDealsAnswers): Promise<void> => {
    setDealsRequest(RD.Loading)

    const { rateType, initialPeriod, mortgageTerm, sortBy } = answers

    try {
      const parsedRateType =
        rateType === "Fixed" ? RateType.Fixed : RateType.Variable

      const initialProduct = `${initialPeriod}${rateType}`

      const deals = await getMortgageDeals({
        buyerType: BuyerType.Remortgage,
        repaymentType: RepaymentMethod.CapitalAndInterest,
        rateType: parsedRateType,
        initialPeriod,
        sortBy,
        borrowingAmount: poundsToPence(monthlyPayments.borrowingAmount),
        propertyValue: poundsToPence(monthlyPayments.propertyValue),
        mortgageTerm,
        mortgageType: MortgageType.Residential,
        initialProduct,
        newBuild: null,
      })
      setDealsRequest(RD.Success(deals))
    } catch (e) {
      const err = e instanceof Error ? e : new Error(`${e}`)
      setDealsRequest(RD.Failure(err))
      logToSentry("Error fetching mortgage deals in Remo DealsSection", err)
    }
  }

  useEffect(() => {
    fetchDeals({
      rateType: DEFAULT_RATE_TYPE,
      initialPeriod: cachedInitialPeriod,
      mortgageTerm: cachedMortgageTerm,
      sortBy: DEFAULT_SORT_BY,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasResults =
    dealsRequest._tag === "Success" && dealsRequest.result.length > 0

  return (
    <Section label="Live deals" ref={sectionRef} tabIndex={-1}>
      <InputCard
        headingId="habito-mortgage-calculator-remo-input-deals-heading"
        title="Filter live deals"
      >
        <FormProvider
          formSpec={formSpec}
          showErrors={showErrors}
          previousState={null}
          prepopulationAnswers={{
            initialPeriod: monthlyPayments.initialPeriod,
            mortgageTerm: monthlyPayments.mortgageTerm.value.toString(),
          }}
        >
          {(formState, extractAnswers) => (
            <FormInner>
              {formState.rateType && (
                <RadioButtonGroup
                  title="Rate type"
                  name="rate-type"
                  buttons={[
                    { label: "Fixed", value: RateType.Fixed },
                    { label: "Variable", value: RateType.Variable },
                  ]}
                  layout="row"
                  selectedValue={formState.rateType.value || undefined}
                  onChange={formState.rateType.onChange}
                  errorMessage={formState.rateType.error}
                />
              )}

              {formState.initialPeriod && (
                <SelectInput
                  title="Initial period (years)"
                  options={initialPeriodSelectOptions}
                  selected={formState.initialPeriod.value}
                  onChange={formState.initialPeriod.onChange}
                  error={formState.initialPeriod.error}
                />
              )}

              {formState.mortgageTerm && (
                <IntegerInputQuestion
                  title="Mortgage term"
                  hint={{ kind: "right", text: "years" }}
                  value={formState.mortgageTerm.value || undefined}
                  onInput={formState.mortgageTerm.onChange}
                  error={formState.mortgageTerm.error}
                />
              )}

              {formState.sortBy && (
                <SelectInput
                  title="Sort by"
                  options={{
                    [Ordering.LendersFeeAsc]: "Lender fees",
                    [Ordering.OverallCostAsc]: "Overall cost",
                    [Ordering.MonthlyRepaymentAsc]: "Monthly repayment",
                    [Ordering.InterestRateAsc]: "Interest rate",
                  }}
                  selected={formState.sortBy.value as NarrowedOrdering}
                  onChange={formState.sortBy.onChange}
                  error={formState.sortBy.error}
                />
              )}

              {showErrors && formError && (
                <Body
                  css={css`
                    color: ${colours.destructive.main};
                  `}
                >
                  {formError}
                </Body>
              )}

              <PrimaryTwoCTA
                text="Filter"
                disabled={RD.isLoading(dealsRequest)}
                isLoading={RD.isLoading(dealsRequest)}
                onClick={() => {
                  setShowErrors(true)
                  const answers = extractAnswers()
                  if (!answers) return

                  const { rateType, initialPeriod, mortgageTerm, sortBy } =
                    answers

                  if (
                    rateType !== undefined &&
                    initialPeriod !== undefined &&
                    mortgageTerm !== undefined &&
                    sortBy
                  ) {
                    const parsedMortgageTerm = parseInt(mortgageTerm, 10)
                    const initialPeriodNumber =
                      initialPeriodToNumber(initialPeriod)

                    if (
                      !isNaN(initialPeriodNumber) &&
                      parsedMortgageTerm < initialPeriodNumber
                    ) {
                      setFormError(
                        "Please choose a mortgage term at least as long as the initial period"
                      )
                      return
                    }

                    setFormError(null)
                    setCachedInitialPeriod(initialPeriod)
                    setCachedMortgageTerm(mkYears(parsedMortgageTerm))
                    setCachedSortBy(sortBy)

                    fetchDeals({
                      rateType,
                      initialPeriod,
                      mortgageTerm: mkYears(parsedMortgageTerm),
                      sortBy,
                    })

                    logSubmittedForm({
                      rateType,
                      initialPeriod,
                      mortgageTerm: parsedMortgageTerm,
                      sortBy,
                    })

                    if (sectionRef.current) {
                      window.scrollBy({
                        behavior: allowsMotion ? "smooth" : "auto",
                        top: sectionRef.current.getBoundingClientRect().top,
                      })
                    }
                  } else {
                    logUndefinedFields(
                      logToSentry,
                      "Error submitting form in Remo PaymentsSection",
                      {
                        rateType,
                        initialPeriod,
                        mortgageTerm,
                        sortBy,
                      }
                    )
                  }
                }}
                width="full-width"
              />
            </FormInner>
          )}
        </FormProvider>
      </InputCard>

      <ContentArea>
        <EduContent ordering={cachedSortBy} hasResults={hasResults} />
        <DealsCard
          dealsRequest={dealsRequest}
          sortBy={cachedSortBy}
          mortgageType={BuyerType.Remortgage}
          onClickSeeOtherCosts={async () => {
            return
          }}
          shouldShowOtherCostsButton={false}
        />
      </ContentArea>
    </Section>
  )
}

export default DealsSection
