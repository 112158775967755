import React from "react"
import { css } from "@emotion/react"

import {
  ArrowDownThickIcon,
  ArrowRightThickIcon,
  SecondaryCTA,
  SecondaryLink,
  Spinner,
  breakpoints,
  horizontal,
  vertical,
  mixins,
} from "design-kit"

import * as RD from "@heyhabito/remote-data"

import { useUser } from "../../../../shared-components/Shared/UserProvider"
import {
  BuyerType,
  Ordering,
  MortgageDeal,
} from "../../../../shared-components/Calculators/types"
import { ProductCard } from "../../../../shared-components/Calculators/ProductCard"
import { setTagText } from "../../../../shared-components/Calculators/formatting"

import { useAmplitude } from "../../../Shared/utils/amplitude"

import { NoResultsCard } from "../NoResultsCard"

const noResultsCard = (
  <NoResultsCard>
    Try changing the property value or mortgage amount.
  </NoResultsCard>
)

const loadingSpinner = (
  <div
    css={css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: ${vertical.m} 0;
    `}
  >
    <Spinner width={48} height={48} />
  </div>
)

const HEADING_ID = "habito-mortgage-calculator-deals-heading"

export const DealsCard: React.FC<{
  dealsRequest: RD.RemoteData<Error, MortgageDeal[]>
  mortgageType: BuyerType
  sortBy: Ordering
  onClickSeeOtherCosts: () => Promise<void>
  shouldShowOtherCostsButton: boolean
}> = ({
  dealsRequest,
  mortgageType,
  sortBy,
  onClickSeeOtherCosts,
  shouldShowOtherCostsButton,
}) => {
  const [numDealsVisible, setNumDealsVisible] = React.useState<number>(3)
  React.useEffect(() => {
    setNumDealsVisible(3) // Reset when the user filters for new deals
  }, [dealsRequest])

  const user = useUser()
  const { logClickedLink, logClickedButton } = useAmplitude(
    mortgageType,
    "deals_section"
  )

  const linkHref =
    mortgageType === "NewMortgage"
      ? user
        ? "/mip"
        : "/sign-up?referrer=mip"
      : "/how-can-we-help/remortgage"

  return (
    <article aria-labelledby={HEADING_ID}>
      <h2
        css={css`
          ${mixins.invisible}
        `}
        id={HEADING_ID}
      >
        Live deals
      </h2>

      {RD.caseOf(
        dealsRequest,
        loadingSpinner,
        deals =>
          deals.length === 0 ? (
            noResultsCard
          ) : (
            <React.Fragment>
              <ul>
                {deals.map((deal, i) => {
                  if (i > numDealsVisible - 1) {
                    return null
                  }

                  return (
                    <ProductCard
                      key={deal.id}
                      displayCompareButton={false}
                      deal={deal}
                      tagText={i === 0 ? setTagText(sortBy) : undefined}
                      logClickedLink={logClickedLink}
                      primaryItemsCount={5}
                    />
                  )
                })}
              </ul>

              <div
                css={css`
                  ${breakpoints.tablet`
                    display: flex;
                  `}
                `}
              >
                {numDealsVisible < deals.length && (
                  <SecondaryCTA
                    css={css`
                      margin-bottom: ${vertical.s};

                      ${breakpoints.tablet`
                        margin-bottom: 0;
                      `}
                    `}
                    text="Load more"
                    onClick={() => {
                      setNumDealsVisible(ndv => ndv + 3)
                      logClickedButton("load_more")
                    }}
                    width="full-width"
                    icon={{ kind: "right", icon: <ArrowDownThickIcon /> }}
                  />
                )}

                {mortgageType === "NewMortgage" &&
                  shouldShowOtherCostsButton && (
                    <SecondaryCTA
                      css={css`
                        ${breakpoints.tablet`
                        margin-left: ${horizontal.m};
                      `}
                      `}
                      text="Find out about any additional costs"
                      onClick={() => {
                        logClickedButton("find_out_additional_costs")
                        onClickSeeOtherCosts()
                      }}
                      width="full-width"
                      icon={{ kind: "right", icon: <ArrowDownThickIcon /> }}
                    />
                  )}

                {mortgageType === "Remortgage" && (
                  <SecondaryLink
                    css={css`
                      ${breakpoints.tablet`
                        margin-left: ${horizontal.m};
                      `}
                    `}
                    text="Start your remortgage - it’s free!"
                    href={linkHref}
                    onClick={() => {
                      logClickedLink("start_remortgage", linkHref)
                    }}
                    width="full-width"
                    icon={{ kind: "right", icon: <ArrowRightThickIcon /> }}
                  />
                )}
              </div>
            </React.Fragment>
          ),
        () => noResultsCard
      )}
    </article>
  )
}
