import React, { FC } from "react"

import { FormSpec, FormProvider } from "@heyhabito/form-utils"
import { useSentry } from "@heyhabito/sentry"

import { GBPInputQuestion, PrimaryTwoCTA } from "design-kit"

import { useAmplitude } from "../../../Shared/utils/amplitude"

import { FormInner } from "../../components/Form"
import { validateIsPositiveNumber } from "../../utils/validation"
import { logUndefinedFields } from "../../utils/forms"
import {
  Pounds,
  mkPounds,
  BuyerType,
} from "../../../../shared-components/Calculators/types"

interface BorrowingAnswers {
  income: string
  deposit: string
}

interface ParsedBorrowingAnswers {
  income: Pounds
  deposit: Pounds
}

const formSpec: FormSpec<BorrowingAnswers> = {
  income: { toError: validateIsPositiveNumber },
  deposit: { toError: validateIsPositiveNumber },
}

const BorrowingInputCard: FC<{
  existingAnswers?: ParsedBorrowingAnswers
  onCalculate: (ppa: ParsedBorrowingAnswers) => Promise<void>
}> = ({ existingAnswers, onCalculate }) => {
  const { logToSentry } = useSentry()
  const { logSubmittedForm } = useAmplitude(
    BuyerType.NewMortgage,
    "borrowing_section"
  )

  const [showErrors, setShowErrors] = React.useState<boolean>(false)

  return (
    <FormProvider
      formSpec={formSpec}
      showErrors={showErrors}
      previousState={null}
      prepopulationAnswers={{
        income: existingAnswers?.income.value.toString(),
        deposit: existingAnswers?.deposit.value.toString(),
      }}
    >
      {(formState, extractAnswers) => (
        <FormInner>
          {formState.income && (
            <GBPInputQuestion
              title="What’s your income?"
              description="If you’re applying with someone else, include their income too."
              value={formState.income.value || ""}
              error={formState.income.error}
              onInput={formState.income.onChange}
            />
          )}

          {formState.deposit && (
            <GBPInputQuestion
              title="How much is your deposit?"
              value={formState.deposit.value || ""}
              error={formState.deposit.error}
              onInput={formState.deposit.onChange}
            />
          )}

          <PrimaryTwoCTA
            text="Calculate"
            onClick={async () => {
              setShowErrors(true)
              const answers = extractAnswers()
              if (!answers) return

              const { income, deposit } = answers
              if (income !== undefined && deposit !== undefined) {
                const parsedIncome = parseFloat(income)
                const parsedDeposit = parseFloat(deposit)

                onCalculate({
                  income: mkPounds(parsedIncome),
                  deposit: mkPounds(parsedDeposit),
                })

                logSubmittedForm({ income, deposit })
              } else {
                logUndefinedFields(
                  logToSentry,
                  "Error submitting form in NewMo BorrowingSection",
                  { income, deposit }
                )
              }
            }}
            width="full-width"
          />
        </FormInner>
      )}
    </FormProvider>
  )
}

export default BorrowingInputCard
