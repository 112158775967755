import React from "react"
import { css, Global } from "@emotion/react"
import { Collapse as ReactCollapse } from "react-collapse"
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TertiaryTwoCTA,
  breakpoints,
  colours,
  horizontal,
  media,
  vertical,
} from "design-kit"

const ANIMATION_DURATION_MS = 300

const FIRST_COLLAPSING_DELAY_MS = 200

export const STEP_TRANSITION_DURATION_MS =
  FIRST_COLLAPSING_DELAY_MS + ANIMATION_DURATION_MS

// "noCollapse" means to hide the collapsible UI and show the children as normal
export type CollapseState = "noCollapse" | "collapsed" | "expanded"

/**
 * When we use the collapsible component, we always want the same behaviour:
 *  - If the section is the current section that the user is working through,
 *    hide the collapsible UI; otherwise default to collapsed
 *  - Watch for changes to `isCurrentSection` and update the collapsible state
 *    accordingly
 *  - But also provide the option to programmatically expand/collapse the UI,
 *    for example when the user edits a section they had previously completed
 */
export const useCollapseState = (
  isCurrentSection: boolean
): [CollapseState, (cs: CollapseState) => void] => {
  const [collapseState, setCollapseState] = React.useState<CollapseState>(
    isCurrentSection ? "noCollapse" : "collapsed"
  )

  React.useEffect(() => {
    if (!isCurrentSection && collapseState === "noCollapse") {
      setCollapseState("expanded")

      // If we change this too abruptly the animation doesn't work very well
      setTimeout(() => {
        setCollapseState("collapsed")
      }, FIRST_COLLAPSING_DELAY_MS)
    }
  }, [collapseState, isCurrentSection])

  return [collapseState, setCollapseState]
}

export const CollapsibleSection: React.FC<{
  id: string
  collapseState: CollapseState
  setCollapseState: (cs: CollapseState) => void
  addNegativeBottomMargin?: boolean
}> = ({
  id,
  collapseState,
  setCollapseState,
  addNegativeBottomMargin = false,
  children,
}) => {
  const [isAnimating, setIsAnimating] = React.useState(false)

  const childrenContainerIdRef = React.useRef(id + "-children")

  if (collapseState === "noCollapse") {
    return (
      <div
        css={css`
          margin-bottom: ${addNegativeBottomMargin ? `-${vertical.m}` : 0};
        `}
      >
        {children}
      </div>
    )
  }

  return (
    <div
      css={css`
        margin-left: -${horizontal.s};
        margin-right: -${horizontal.s};

        ${breakpoints.desktop`
          margin-left: -${horizontal.l};
          margin-right: -${horizontal.l};
        `}
      `}
    >
      <Global
        styles={css`
          .ReactCollapse--collapse {
            transition: none;

            ${media.allowsMotion`
              transition-duration: ${ANIMATION_DURATION_MS}ms;
              transition-property: height;
              transition-timing-function: ease-out;
            `}

            padding: 0 ${horizontal.s};

            ${breakpoints.desktop`
              padding: 0 ${horizontal.l};
            `}
          }
        `}
      />

      <ReactCollapse
        isOpened={collapseState === "expanded"}
        onRest={() => {
          setIsAnimating(false)
        }}
        onWork={() => {
          setIsAnimating(true)
        }}
      >
        <div
          aria-labelledby={id}
          id={childrenContainerIdRef.current}
          css={css`
            ${collapseState === "expanded" || isAnimating
              ? ""
              : "display: none;"}
          `}
        >
          {children}
        </div>
      </ReactCollapse>

      <div
        css={css`
          margin-top: ${collapseState === "collapsed" || addNegativeBottomMargin
            ? 0
            : vertical.m};
          margin-bottom: -${vertical.m};
          background-color: ${colours.greyScale.grey25};
        `}
      >
        <TertiaryTwoCTA
          id={id}
          onClick={() => {
            setCollapseState(
              collapseState === "expanded" ? "collapsed" : "expanded"
            )
          }}
          width="full-width"
          text={collapseState === "collapsed" ? "Show more" : "Show less"}
          icon={{
            kind: "right",
            icon:
              collapseState === "collapsed" ? (
                <ChevronDownIcon />
              ) : (
                <ChevronUpIcon />
              ),
          }}
          aria-controls={childrenContainerIdRef.current}
          aria-expanded={collapseState === "expanded"}
          css={css`
            // An inset version of the Design Kit's focus mixin, because the
            // default version gets partially hidden by the surrounding
            // ReactCollapse container that has 'overflow: hidden'
            &:focus-visible {
              box-shadow: inset 0 0 0 3px ${colours.callout.dark};
              outline: none;
              border-bottom-right-radius: 14px;
              border-bottom-left-radius: 14px;
            }
          `}
        />
      </div>
    </div>
  )
}
