export type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export const entries = <T extends {}>(obj: T): Entries<T> => {
  return Object.entries(obj) as Entries<T>
}

export const keys = <T extends {}>(obj: T) =>
  Object.keys(obj) as Array<keyof typeof obj>
