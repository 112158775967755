import React from "react"
import { css } from "@emotion/react"
import {
  ArrowDownThickIcon,
  SecondaryCTA,
  breakpoints,
  colours,
  column,
  horizontal,
  useMediaQuery,
  vertical,
} from "design-kit"

const ContentAreaInner: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <div
    css={css`
      ${column({
        widthMobile: 4,
        widthTablet: 6,
        widthDesktop: 7,
        offsetDesktop: 1,
      })}

      align-self: flex-start;
      margin-top: ${vertical.xxl};

      ${breakpoints.desktop`
        margin-top: 0;
      `}
    `}
  >
    {children}
  </div>
)

export const ContentArea: React.FC = props => {
  const isDesktop = useMediaQuery(breakpoints.desktop.query)

  if (isDesktop) {
    return <ContentAreaInner {...props} />
  }

  return (
    <div
      css={css`
        background: linear-gradient(
          to bottom,
          ${colours.offBlack} 48px,
          ${colours.greyScale.grey25} 48px
        );

        margin-left: -${horizontal.s};
        padding-left: ${horizontal.s};
        width: 100%;

        padding-bottom: ${vertical.l};

        ${breakpoints.tablet`
          margin-left: -${horizontal.l};
          margin-right: -${horizontal.s};
          padding-left: ${horizontal.l};
          padding-right: ${horizontal.s};
        `}
      `}
    >
      <ContentAreaInner {...props} />
    </div>
  )
}

export const NextSectionCTA: React.FC<{
  text: string
  onClick: () => void
}> = ({ text, onClick }) => (
  <SecondaryCTA
    text={text}
    onClick={onClick}
    width="full-width"
    icon={{
      kind: "right",
      icon: <ArrowDownThickIcon />,
    }}
    css={css`
      margin-top: ${vertical.m};
    `}
  />
)
