import React, { FC } from "react"
import { css } from "@emotion/react"

import {
  Body,
  breakpoints,
  colours,
  column,
  GridRow,
  horizontal,
  typographyStyles,
  vertical,
  useMediaQuery,
  TextLink,
} from "design-kit"

import { BuyerType } from "../../shared-components/Calculators/types"

const Hero: FC<{ mortgageType: BuyerType }> = ({ mortgageType }) => {
  const isDesktop = useMediaQuery(breakpoints.desktop.query)
  const textLinkColour = isDesktop ? "white" : "black"

  return (
    <div
      css={css`
        padding-top: 120px;

        ${breakpoints.desktop`
          margin-bottom: ${vertical.l};
        `}
      `}
    >
      <GridRow>
        <h1
          css={css`
            ${column({
              widthMobile: 4,
              widthTablet: 6,
              widthDesktop: 12,
            })}

            ${typographyStyles.statement2}
          `}
        >
          <span
            css={css`
              display: inline-block;
              margin-bottom: ${vertical.s};

              ${breakpoints.desktop`
                background-color: ${colours.offBlack};
                color: ${colours.white};
                padding: ${vertical.xxs} ${horizontal.m} ${vertical.s} 0;
              `}
            `}
          >
            {mortgageType === "Remortgage"
              ? "Remortgage calculator"
              : "Mortgage calculator"}
          </span>
        </h1>

        <Body
          css={css`
            ${column({
              widthMobile: 4,
              widthTablet: 4,
              widthDesktop: 3,
            })}

            ${breakpoints.desktop`
              color: ${colours.white};
            `}
          `}
        >
          {mortgageType === "NewMortgage" &&
            "Buying a home and want to know what you could borrow? "}
          {mortgageType === "Remortgage" &&
            "Want to know what you could save by remortgaging? "}
          Let’s crunch some numbers together!
          {isDesktop &&
            mortgageType === "NewMortgage" &&
            " See what you could afford and what you could be paying every month."}
          {isDesktop &&
            mortgageType === "Remortgage" &&
            " See what you could save and what you could be paying every month."}
          {mortgageType === "NewMortgage" && (
            <React.Fragment>
              {" "}
              Remortgaging?{" "}
              <TextLink
                text="We have a calculator for that too"
                href="/remortgage-calculator"
                colour={textLinkColour}
              />
            </React.Fragment>
          )}
          {mortgageType === "Remortgage" && (
            <React.Fragment>
              {" "}
              Purchasing?{" "}
              <TextLink
                text="We have a calculator for that too"
                href="/mortgage-calculator"
                colour={textLinkColour}
              />
            </React.Fragment>
          )}
        </Body>
      </GridRow>
    </div>
  )
}

export default Hero
