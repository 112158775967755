import React from "react"
import { css } from "@emotion/react"
import {
  BodySmall,
  borderRadii,
  breakpoints,
  colours,
  horizontal,
  InfoIcon,
  mixins,
  TextLink,
  typographyStyles,
  useMediaQuery,
  vertical,
} from "design-kit"

import { useUser } from "../../../shared-components/Shared/UserProvider"
import {
  Pounds,
  Percentage,
} from "../../../shared-components/Calculators/types"
import { formatCurrency } from "../../../shared-components/Calculators/formatting"

type Props =
  | {
      mortgageType: "NewMortgage"
      borrowing: Pounds
      deposit: Pounds
    }
  | {
      mortgageType: "Remortgage"
      equity: Percentage
      propertyValue: Pounds
      remainingBalance: Pounds
    }

const HighLoanToValueWarning: React.VFC = () => {
  const user = useUser()

  return (
    <BodySmall
      css={css`
        align-items: flex-start;
        display: flex;
        margin-top: ${vertical.xxs};

        svg {
          flex-shrink: 0;
          height: auto;
          margin-right: ${horizontal.s};
          width: 20px;
        }
      `}
    >
      <InfoIcon color="currentColor" />

      <span>
        <strong>It looks like your loan to value (LTV) is more than 95%</strong>{" "}
        – we don't have any mortgage deals that match that right now. Try double
        checking your property value, or{" "}
        <TextLink
          css={css`
            color: currentColor;
          `}
          href={user ? "/my-properties" : "/sign-up"}
          text="chat to a Habito expert"
        />{" "}
        for personalised advice!
      </span>
    </BodySmall>
  )
}

export const PercentageGraph: React.FC<Props> = props => {
  // Math.round with * 10 then / 10 is for rounding with up to 1 decimal place
  const mainPercentage =
    props.mortgageType === "NewMortgage"
      ? // Loan to value
        Math.round(
          (props.borrowing.value /
            (props.borrowing.value + props.deposit.value)) *
            100 *
            10
        ) / 10
      : // Equity
        Math.round(props.equity.value * 10) / 10

  const isMobile = !useMediaQuery(breakpoints.tablet.query)

  /**
   * We don't render this component in the Remortgage Calculator if the LTV is
   * too high because sourcing won't return any deals
   */
  const isLoanToValueTooHigh =
    props.mortgageType === "NewMortgage" ? mainPercentage > 95 : false

  return (
    <figure>
      <figcaption css={css(mixins.invisible)}>
        {props.mortgageType === "NewMortgage"
          ? `Your deposit is ${formatCurrency(
              props.deposit
            )} and you are borrowing ${formatCurrency(
              props.borrowing
            )}. That means your loan to value is ${mainPercentage}%.`
          : `Your mortgage balance is ${formatCurrency(
              props.remainingBalance
            )} and you already own ${mainPercentage}% of your home.`}
      </figcaption>

      <div
        css={css`
          background-color: ${colours.offBlack};
          color: ${colours.white};
          margin: 0 -${horizontal.s};
          padding: ${vertical.xs} ${horizontal.s};

          ${breakpoints.desktop`
            margin: 0 -${horizontal.l};
            padding: ${vertical.xs} ${horizontal.l};
          `}
        `}
      >
        <div
          aria-hidden
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            ${breakpoints.desktop`
              align-items: center;
              flex-direction: row;
            `}
          `}
        >
          <BodySmall>
            {props.mortgageType === "NewMortgage"
              ? "Your loan to value: "
              : "Your equity (how much you own): "}

            <strong
              css={css`
                margin-left: ${horizontal.xxs};
              `}
            >
              {mainPercentage}%
            </strong>
          </BodySmall>

          {isMobile &&
            isLoanToValueTooHigh &&
            props.mortgageType === "Remortgage" && <HighLoanToValueWarning />}

          <dl
            css={css`
              display: flex;
              margin-top: ${vertical.xs};

              dt {
                border-radius: ${borderRadii.xs};
                flex-shrink: 0;
                height: 24px;
                width: 24px;

                :not(:first-of-type) {
                  margin-left: ${horizontal.m};
                }

                p {
                  ${mixins.invisible}
                }
              }

              dd {
                ${typographyStyles.body}

                margin-left: calc(${horizontal.m} / 2);
              }

              ${breakpoints.desktop`
              margin-top: 0;
            `}
            `}
          >
            <dt
              css={css`
                background-color: ${colours.gradientBase.bubblegum};
              `}
            >
              <p>Pink</p>
            </dt>
            <dd>
              {props.mortgageType === "NewMortgage" ? "Deposit" : "Equity"}
            </dd>

            <dt
              css={css`
                background-color: ${colours.gradientBase.sunshine};
              `}
            >
              <p>Yellow</p>
            </dt>
            <dd>
              {props.mortgageType === "NewMortgage"
                ? "Borrowing"
                : "Mortgage balance"}
            </dd>
          </dl>
        </div>

        {!isMobile &&
          isLoanToValueTooHigh &&
          props.mortgageType === "Remortgage" && <HighLoanToValueWarning />}
      </div>

      <div
        aria-hidden
        css={css`
          display: flex;
          height: 25px;
          margin: 0 -${horizontal.s};

          div {
            height: 100%;
          }

          ${breakpoints.desktop`
            margin: 0  -${horizontal.l};
          `}
        `}
      >
        <div
          css={css`
            background-color: ${colours.gradientBase.bubblegum};
            flex-basis: ${props.mortgageType === "NewMortgage"
              ? `calc(100% - ${mainPercentage}%)`
              : mainPercentage + "%"};
          `}
        />

        <div
          css={css`
            background-color: ${colours.gradientBase.sunshine};
            flex-grow: 1;
          `}
        />
      </div>
    </figure>
  )
}
