import React from "react"
import { css } from "@emotion/react"
import { borderRadii, colours, horizontal, vertical } from "design-kit"

export const GradientSpan: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <span
    css={css`
      background: linear-gradient(
        to bottom right,
        ${colours.gradientBase.seafoam},
        ${colours.gradientBase.mistyViolet}
      );
      border-radius: ${borderRadii.xs};
      padding: 0 ${horizontal.s} calc(${vertical.xxs} / 2);
    `}
  >
    {children}
  </span>
)
