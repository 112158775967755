import { css } from "@emotion/react"
import {
  Body,
  breakpoints,
  colours,
  HandPointLeft2Icon,
  horizontal,
  vertical,
  iconSize,
} from "design-kit"
import React from "react"

export const TIP_TEXT = "Answer a few questions to find out!"

export const AnswerQuestionsTip: React.VFC<{ text?: string }> = ({
  text = TIP_TEXT,
}) => (
  <div
    css={css`
      display: none;

      ${breakpoints.desktop`
        align-items: center;
        background-color: ${colours.greyScale.grey25};
        display: flex;
        margin: ${vertical.m} -${horizontal.l} -${vertical.m};
        padding: ${vertical.xs} ${horizontal.l};
      `}
    `}
  >
    <HandPointLeft2Icon height={iconSize.m} width={iconSize.m} />

    <Body
      css={css`
        margin-left: ${horizontal.m};
      `}
    >
      {text}
    </Body>
  </div>
)
