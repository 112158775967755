import React, { FC } from "react"

import { breakpoints, useMediaQuery } from "design-kit"

import config from "../../config.json"

import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import MortgageCalculator from "../views/MortgageCalculator"
import { BuyerType } from "../shared-components/Calculators/types"

interface SEOData {
  title: string
  metaDescription: string
  canonical: string
}

interface PageContext {
  isRemo: boolean
  seoData: SEOData
}

export const MortgageCalculatorPage: FC<{
  pageContext: PageContext
}> = ({ pageContext }) => {
  const { isRemo, seoData } = pageContext
  const pageName = `${isRemo ? "re" : ""}mortgage_calculator`

  const isDesktop = useMediaQuery(breakpoints.desktop.query)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <WrappedBase
      metaTitle={seoData.title}
      metaDescription={seoData.metaDescription}
      canonicalUrl={seoData.canonical}
      noIndex={false}
      pageName={pageName}
      intercom={true}
      config={config}
    >
      <Navbar theme={isDesktop ? "opaque" : "transparent-dark"} />

      <MortgageCalculator
        mortgageType={isRemo ? BuyerType.Remortgage : BuyerType.NewMortgage}
      />

      <Footer />
    </WrappedBase>
  )
}
export default MortgageCalculatorPage
