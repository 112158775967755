import React from "react"

import { Alert, colours } from "design-kit"
import styled from "@emotion/styled"

const Box = styled.div<{ margin?: string }>`
  background-color: ${colours.white};
  margin: ${({ margin }) => margin || ""};
`

export const NoResultsCard: React.FC<{ margin?: string }> = ({
  children,
  margin,
}) => (
  <Box margin={margin}>
    <Alert kind="warning" title="No results">
      {children}
    </Alert>
  </Box>
)
