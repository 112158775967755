import { css } from "@emotion/react"
import {
  SecondaryCTA,
  borderRadii,
  colours,
  horizontal,
  typographyStyles,
  vertical,
} from "design-kit"
import React from "react"

interface Props {
  items: Array<{
    label: string
    value: string
  }>
  onEdit: () => void
}

export const ReadOnlyAnswers: React.VFC<Props> = ({ items, onEdit }) => (
  <div>
    <dl
      css={css`
        margin-bottom: ${vertical.xs};
      `}
    >
      {items.map(({ label, value }) => (
        <div
          css={css`
            background-color: ${colours.greyScale.grey25};
            border-radius: ${borderRadii.xs};
            display: flex;
            justify-content: space-between;
            padding: ${vertical.xxs} ${horizontal.s};

            :not(:first-of-type) {
              margin-top: ${vertical.xs};
            }
          `}
          key={label}
        >
          <dt
            css={css`
              ${typographyStyles.body}

              margin-right: ${horizontal.m};
            `}
          >
            {label}
          </dt>

          <dd
            css={css`
              ${typographyStyles.bodyBold}
            `}
          >
            {value}
          </dd>
        </div>
      ))}
    </dl>

    <SecondaryCTA text="Edit" onClick={onEdit} width="full-width" />
  </div>
)
