import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {
  breakpoints,
  colours,
  column,
  GridRow,
  media,
  mixins,
  ResponsiveImage,
  useHasMounted,
  useMediaQuery,
} from "design-kit"
import React from "react"

import groundImg from "./assets/ground.jpg"
import groundRetinaImg from "./assets/ground-retina.jpg"
import housePng from "./assets/house.png"
import houseRetinaPng from "./assets/house-retina.png"
import houseRetinaWebp from "./assets/house-retina.webp"
import houseWebp from "./assets/house.webp"

/**
 * Safari doesn't seem to play nicely with `transparent` in our gradients, so we
 * use this instead
 *
 * (https://stackoverflow.com/a/27118826)
 */

const TRANSPARENT = "rgba(255,255,255,0)"

const LinearGradientBackground = styled.div`
  // Fallback
  background-color: ${colours.gradientBase.mistyViolet};

  background: linear-gradient(
    to bottom,
    ${colours.gradientBase.bubblegum},
    ${colours.gradientBase.electricGrape}
  );
`

// 1440px / 4 = 360px, minus some extra spacing, but with 10–15px to spare
const LEFT_COLUMN_END_ESTIMATE = "calc(50% - 330px)"

const RadialGradientsBackground: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <React.Fragment>
    {/* Top right circle */}
    <div
      css={css`
        background: radial-gradient(
          circle at top right,
          ${colours.gradientBase.sunshine},
          ${TRANSPARENT} 90%
        );
        height: 100%;
        opacity: 0.9;
        position: absolute;
        width: 100%;
      `}
    />

    {/* Bottom left circle */}
    <div
      css={css`
        background: radial-gradient(
          circle at bottom left,
          #64d6fa,
          ${TRANSPARENT} 50%
        );
        height: 100%;
        opacity: 0.9;
        position: absolute;
        width: 100%;
      `}
    />

    {children}
  </React.Fragment>
)

const Background: React.FC = () => {
  const isDesktop = useMediaQuery(breakpoints.desktop.query)

  if (isDesktop) {
    return (
      <React.Fragment>
        {/*  Right gray background */}
        <div
          css={css`
            background-color: ${colours.greyScale.grey25};
            height: 100%;
            left: ${LEFT_COLUMN_END_ESTIMATE};
            position: absolute;
            right: 0;
          `}
        />

        <LinearGradientBackground
          css={css`
            height: 570px;
            left: ${LEFT_COLUMN_END_ESTIMATE};
            position: absolute;
            right: 0;
            top: 0;
          `}
        >
          <RadialGradientsBackground>
            {/* Ground */}
            <div
              css={css`
                // Fallback, main color picked from the image
                background-color: #e69d99;

                background-image: url("${groundImg}");
                background-repeat: repeat-x;
                bottom: 0;
                height: 180px;
                position: absolute;
                transform: translateY(100%);
                width: 100%;

                ${media.retinaScreen`
                  background-image: url("${groundRetinaImg}");
                `}
              `}
            />

            {/* House */}
            <ResponsiveImage
              srcRetinaWebP={houseRetinaWebp}
              srcRetina={houseRetinaPng}
              srcWebP={houseWebp}
              srcDefault={housePng}
              imageType="image/png"
              alt=""
              imgCss={css`
                bottom: -150px;
                left: 262px;
                position: absolute;
                width: 676px;
              `}
            />
          </RadialGradientsBackground>
        </LinearGradientBackground>

        {/* Left black background */}
        <GridRow
          css={css`
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          `}
        >
          <div
            css={css`
              ${column({
                collapseMarginsDesktop: true,
                widthDesktop: 3,
              })}

              background-color: ${colours.offBlack};
            `}
          />
        </GridRow>
      </React.Fragment>
    )
  }

  return (
    <div
      css={css`
        background-color: ${colours.offBlack};
        position: absolute;
        top: 0;
        width: 100%;
      `}
    >
      <LinearGradientBackground
        css={css`
          height: 434px;
          position: relative;

          ${breakpoints.tablet`
            height: 486px;
          `}
        `}
      >
        {/* Top right circle */}
        <div
          css={css`
            background: radial-gradient(
              ellipse at top 10% right 20%,
              ${colours.gradientBase.sunshine},
              ${TRANSPARENT} 80%
            );
            height: 100%;
            opacity: 0.9;
            position: absolute;
            width: 100%;
          `}
        />

        {/* Bottom left circle */}
        <div
          css={css`
            background: radial-gradient(
              ellipse at bottom -20% left 10%,
              #64d6fa,
              ${TRANSPARENT} 80%
            );
            height: 100%;
            opacity: 0.9;
            position: absolute;
            width: 100%;
          `}
        />
      </LinearGradientBackground>
    </div>
  )
}

const Main: React.FC = ({ children }) => {
  const hasMounted = useHasMounted()

  const content = (
    <React.Fragment>
      <Background />

      {children}
    </React.Fragment>
  )

  // Prevents content flashing
  if (!hasMounted) {
    return (
      <LinearGradientBackground
        css={css`
          height: 764px;
          position: relative;
        `}
      >
        <RadialGradientsBackground />

        {/* Attempt to prevent harming SEO by delivering content from SSR */}
        <div css={css(mixins.invisible)}>{content}</div>
      </LinearGradientBackground>
    )
  }

  return (
    <main
      css={css`
        background-color: ${colours.greyScale.grey25};

        ${breakpoints.desktop`
          background-color: ${colours.offBlack};
          position: relative;
        `}
      `}
    >
      {content}
    </main>
  )
}

export default Main
