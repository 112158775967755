import { useHasMounted } from "design-kit"
import React, { FC } from "react"

import { WithUserProvider } from "../../shared-components/Shared/UserProvider"

import {
  Country,
  NewMortgageBorrowingAmounts,
  LiveDealsFilters,
  RemortgageMonthlyPayments,
} from "./types"
import {
  mkYears,
  BuyerType,
  RepaymentMethod,
  Ordering,
} from "../../shared-components/Calculators/types"

import Hero from "./Hero"
import Main from "./Main"
import FAQsSection from "./FAQsSection"
import * as NewMortgage from "./NewMortgage"
import * as Remortgage from "./Remortgage"

type StepState<T> = { type: "Unanswered" } | { type: "Answered"; data: T }

type NewMortgageState =
  | {
      step: "BorrowingSection"
      stepState: StepState<NewMortgageBorrowingAmounts>
    }
  | {
      step: "Deals"
      borrowingAmounts: NewMortgageBorrowingAmounts
      liveDealsFilters: LiveDealsFilters
      isOtherCostsExpanded: boolean
      otherCostsAnswers: { country: Country; isFirstTimeBuyer: boolean } | null
    }

const NewMortgageFlow: FC<{
  state: NewMortgageState
  setState: (nms: NewMortgageState) => void
  borrowRef: React.RefObject<HTMLDivElement>
  firstRender: React.MutableRefObject<boolean>
}> = ({ state, setState, borrowRef, firstRender }) => {
  let shouldScroll = false
  if (firstRender.current) {
    firstRender.current = false
  } else {
    shouldScroll = true
  }

  switch (state.step) {
    case "BorrowingSection": {
      const stepState = state.stepState

      switch (stepState.type) {
        case "Unanswered": {
          return (
            <NewMortgage.BorrowingSection
              onCalculateBorrowingAmounts={borrowingAmounts => {
                setState({
                  ...state,
                  stepState: { type: "Answered", data: borrowingAmounts },
                })
              }}
              shouldScrollToSection={true}
              borrowRef={borrowRef}
            />
          )
        }

        case "Answered": {
          return (
            <NewMortgage.BorrowingSection
              existingBorrowingAmounts={stepState.data}
              onCalculateBorrowingAmounts={borrowingAmounts => {
                setState({
                  ...state,
                  stepState: { ...stepState, data: borrowingAmounts },
                })
              }}
              onContinue={() => {
                setState({
                  step: "Deals",
                  borrowingAmounts: stepState.data,
                  liveDealsFilters: {
                    repaymentType: RepaymentMethod.CapitalAndInterest,
                    fixedOrVariable: "Fixed",
                    initialPeriod: "2",
                    mortgageTerm: mkYears(25),
                    sortBy: Ordering.OverallCostAsc,
                  },
                  isOtherCostsExpanded: false,
                  otherCostsAnswers: null,
                })
              }}
              shouldScrollToSection={true}
              borrowRef={borrowRef}
            />
          )
        }
      }

      break
    }

    case "Deals": {
      return (
        <React.Fragment>
          <NewMortgage.BorrowingSection
            existingBorrowingAmounts={state.borrowingAmounts}
            onCalculateBorrowingAmounts={borrowingAmounts => {
              setState({ ...state, borrowingAmounts })
            }}
            shouldScrollToSection={false}
            borrowRef={borrowRef}
          />

          <NewMortgage.DealsSection
            borrowingAmounts={state.borrowingAmounts}
            shouldScrollToSection={shouldScroll && !state.isOtherCostsExpanded}
            setIsOtherCostsExpanded={async isOtherCostsExpanded => {
              setState({ ...state, isOtherCostsExpanded })
            }}
          />

          {state.isOtherCostsExpanded && (
            <NewMortgage.OtherCostsSection
              setOtherCostsAnswers={async otherCostsAnswers => {
                setState({ ...state, otherCostsAnswers })
              }}
              otherCostsAnswers={state.otherCostsAnswers}
              propertyValue={state.borrowingAmounts.maxPropertyValue}
              shouldScrollToSection={shouldScroll}
            />
          )}
        </React.Fragment>
      )
    }
  }
}

type RemortgageState =
  | { step: "PaymentsSection"; stepState: StepState<RemortgageMonthlyPayments> }
  | { step: "Deals"; monthlyPayments: RemortgageMonthlyPayments }

const RemortgageFlow: FC<{
  state: RemortgageState
  setState: (nms: RemortgageState) => void
  firstRender: React.MutableRefObject<boolean>
}> = ({ state, setState, firstRender }) => {
  let shouldScroll = false
  if (firstRender.current) {
    firstRender.current = false
  } else {
    shouldScroll = true
  }

  switch (state.step) {
    case "PaymentsSection": {
      const stepState = state.stepState
      switch (stepState.type) {
        case "Unanswered": {
          return (
            <Remortgage.PaymentsSection
              onCalculate={monthlyPayments => {
                setState({
                  ...state,
                  stepState: { type: "Answered", data: monthlyPayments },
                })
              }}
              shouldFocusSection={shouldScroll}
            />
          )
        }

        case "Answered": {
          return (
            <Remortgage.PaymentsSection
              existingMonthlyPayments={stepState.data}
              onCalculate={monthlyPayments => {
                setState({
                  ...state,
                  stepState: { ...stepState, data: monthlyPayments },
                })
              }}
              onContinue={() => {
                setState({
                  step: "Deals",
                  monthlyPayments: stepState.data,
                })
              }}
              shouldFocusSection={shouldScroll}
            />
          )
        }
      }

      break
    }

    case "Deals": {
      return (
        <React.Fragment>
          <Remortgage.PaymentsSection
            existingMonthlyPayments={state.monthlyPayments}
            onCalculate={monthlyPayments => {
              setState({ ...state, monthlyPayments })
            }}
            shouldFocusSection={false}
          />

          <Remortgage.DealsSection
            shouldScrollToSection={shouldScroll}
            monthlyPayments={state.monthlyPayments}
          />
        </React.Fragment>
      )
    }
  }
}

interface PersistedState {
  newMortgageState: NewMortgageState
  remortgageState: RemortgageState
}

const persistedStateKey = "habito/resi-calc-state-v3"

const persistState = (ps: PersistedState): void => {
  if (typeof localStorage !== "undefined") {
    // Store a timestamp with the state, so we can choose not to resume states
    // which are deemed "too old".
    const timestamp = new Date().toISOString()

    localStorage.setItem(
      persistedStateKey,
      JSON.stringify({ timestamp, state: ps })
    )
  }
}

const oneDayInMilliseconds = 24 * 60 * 60 * 1000

const retrieveState = (): PersistedState | null => {
  if (typeof localStorage !== "undefined") {
    const value = localStorage.getItem(persistedStateKey)
    if (value) {
      const { timestamp, state } = JSON.parse(value)

      const persistedAt = new Date(timestamp)
      const now = new Date()

      if (now.getTime() - persistedAt.getTime() < oneDayInMilliseconds) {
        return state
      } else {
        // State is too old -- don't return it.
        return null
      }
    } else {
      return null
    }
  } else {
    return null
  }
}

const retrievedState = retrieveState()

const MortgageCalculator: FC<{ mortgageType: BuyerType }> = ({
  mortgageType,
}) => {
  const firstRender = React.useRef(true)

  // Keep the state for each flow at the top level so that we don't lose the
  // user's progress if they toggle between NewMo and Remo
  const [newMortgageState, setNewMortgageState] =
    React.useState<NewMortgageState>(() => {
      if (retrievedState) {
        return retrievedState.newMortgageState
      } else {
        return {
          step: "BorrowingSection",
          stepState: { type: "Unanswered" },
        }
      }
    })

  const [remortgageState, setRemortgageState] = React.useState<RemortgageState>(
    () => {
      if (retrievedState) {
        return retrievedState.remortgageState
      } else {
        return { step: "PaymentsSection", stepState: { type: "Unanswered" } }
      }
    }
  )

  React.useEffect(() => {
    persistState({ newMortgageState, remortgageState })
  }, [newMortgageState, remortgageState])

  const borrowRef = React.useRef(null)

  /**
   * <Main> already hides component visually on SSR, but we use hasMounted here
   * to prevent interactive fields from being rendered
   */
  const hasMounted = useHasMounted()

  return (
    <WithUserProvider>
      <Main>
        <section aria-label="Mortgage calculator: introduction">
          <Hero mortgageType={mortgageType} />
        </section>

        {hasMounted &&
          (mortgageType === "NewMortgage" ? (
            <NewMortgageFlow
              state={newMortgageState}
              setState={setNewMortgageState}
              borrowRef={borrowRef}
              firstRender={firstRender}
            />
          ) : mortgageType === "Remortgage" ? (
            <RemortgageFlow
              state={remortgageState}
              setState={setRemortgageState}
              firstRender={firstRender}
            />
          ) : null)}
      </Main>

      <FAQsSection mortgageType={mortgageType} />
    </WithUserProvider>
  )
}

export default MortgageCalculator
