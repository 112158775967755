import { SentryLoggerSimple } from "@heyhabito/sentry"

import { InitialPeriod } from "../../../shared-components/Calculators/types"

export const initialPeriodToNumber = (ip: InitialPeriod): number => {
  switch (ip) {
    case "2":
      return 2
    case "3":
      return 3
    case "5":
      return 5
    case "10":
      return 10
    case "FullTerm":
      return NaN
  }
}

export const logUndefinedFields = (
  logToSentry: SentryLoggerSimple,
  desc: string,
  fields: Record<string, unknown>
): void => {
  const undefinedFields = Object.entries(fields).reduce<string[]>(
    (acc, [k, v]) => (v === undefined ? [...acc, k] : acc),
    []
  )
  logToSentry(
    desc,
    new Error(`The following fields were undefined: [${undefinedFields}]`)
  )
}
