import {
  FixedOrVariable,
  InitialPeriod,
  Ordering,
  Percentage,
  Pounds,
  RateType,
  RepaymentMethod,
  Years,
} from "../../shared-components/Calculators/types"

export interface NewMortgageBorrowingAmounts {
  income: Pounds
  deposit: Pounds
  maxBorrowingAmount: Pounds
  maxBorrowingAmountForApi: Pounds
  maxPropertyValue: Pounds
  ssmipBorrowingAmount: Pounds
}

export interface LiveDealsFilters {
  repaymentType: RepaymentMethod
  fixedOrVariable: FixedOrVariable
  initialPeriod: InitialPeriod
  mortgageTerm: Years
  sortBy: Ordering
}

export interface RemortgageMonthlyPayments {
  borrowingAmount: Pounds
  propertyValue: Pounds
  initialPeriod: InitialPeriod
  mortgageTerm: Years
  potentialMonthlyPayments: Pounds
  currentMonthlyPayments: Pounds
  savingsOverProductTerm: Pounds
  equity: Percentage
}

export type Country = "England" | "Scotland" | "Wales" | "Northern Ireland"

export enum NewMortgageFormInputNames {
  RepaymentType = "repaymentType",
  RateType = "rateType",
  InitialPeriod = "initialPeriod",
  MortgageTerm = "mortgageTerm",
  SortBy = "sortBy",
}

type NewMortgageFormInputs = Record<NewMortgageFormInputNames, string>
type CheckNoExtraNames = Record<NewMortgageFormInputNames, never>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CheckForAllNames<T extends NewMortgageFormInputs, _ extends T> = T

export type NewMortgageForm = CheckForAllNames<
  {
    [NewMortgageFormInputNames.RepaymentType]: RepaymentMethod
    [NewMortgageFormInputNames.RateType]: RateType
    [NewMortgageFormInputNames.InitialPeriod]: InitialPeriod
    [NewMortgageFormInputNames.MortgageTerm]: string
    [NewMortgageFormInputNames.SortBy]: Ordering
  },
  CheckNoExtraNames
>

export enum Errors {
  MortgageTermRange = "Mortgage term needs to be between 1 and 40 years.",
  DealPeriodLongerThanMortgageTerm = "Your initial period can't be longer than your mortgage term.",
  MortgageTermShorterThanDealPeriod = "Your mortgage term can't be shorter than your initial period.",
}

export enum ErrorsNames {
  MortgageTermRange = "MortgageTermRange",
  DealPeriodLongerThanMortgageTerm = "DealPeriodLongerThanMortgageTerm",
  MortgageTermShorterThanDealPeriod = "MortgageTermShorterThanDealPeriod",
}

export interface FormErrors {
  [ErrorsNames.MortgageTermRange]?: Errors.MortgageTermRange
  [ErrorsNames.DealPeriodLongerThanMortgageTerm]?: Errors.DealPeriodLongerThanMortgageTerm
  [ErrorsNames.MortgageTermShorterThanDealPeriod]?: Errors.MortgageTermShorterThanDealPeriod
}
