import { css } from "@emotion/react"
import {
  Body,
  breakpoints,
  colours,
  horizontal,
  LinkCTA,
  mixins,
  vertical,
} from "design-kit"
import React from "react"

interface Props {
  ctaProps: Required<
    Pick<React.ComponentProps<typeof LinkCTA>, "href" | "onClick" | "text">
  >
  headingId: string
  text: string
  title: string
}

export const ActionBanner: React.VFC<Props> = ({
  ctaProps,
  headingId,
  text,
  title,
}) => (
  <article
    aria-labelledby={headingId}
    css={css`
      background-color: ${colours.action.main};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 -${horizontal.s};
      padding: ${vertical.xs} ${horizontal.s};

      ${breakpoints.desktop`
        align-items: center;
        flex-direction: row;
        margin: 0 -${horizontal.l};
        padding: ${vertical.xs} ${horizontal.l};
      `}
    `}
  >
    <h3 css={css(mixins.invisible)} id={headingId}>
      {title}
    </h3>

    <Body
      css={css`
        align-items: center;
        color: ${colours.white};
        display: flex;
        margin-bottom: ${vertical.xs};

        ${breakpoints.desktop`
          margin-bottom: 0;
          margin-right: ${horizontal.l};
        `}
      `}
    >
      {text}
    </Body>

    <LinkCTA
      {...ctaProps}
      prominence="secondary"
      width={{
        desktop: "content-width",
        mobile: "full-width",
        tablet: "full-width",
      }}
    />
  </article>
)
